import { JsvServiceClient } from './lib/jsv/jsv.js'
import isBoolean from 'validator/lib/isBoolean'
import toBoolean from 'validator/lib/toBoolean'
// import isNumeric from 'validator/lib/isNumeric'
import parseISO from 'date-fns/parseISO'
import isPlainObject from 'lodash/isPlainObject'
import $ from 'jquery'

import type { Status, StatusError } from '~/utils'
import { UNKNOWN_ERROR } from '~/utils'

/**
 * Narrowly tests the format: 2017-06-17T00:00:00.000Z
 * Other regexes are too broad and will match simple numbers.
 */
const isoDateRegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/

/**
 * @deprecated
 *
 * This converts some string values to JS values, but it can have unexpected
 * side-effects. It's not clear if this is still needed.
 */
const processValue = (
  value: any,
  customizer: (([key, value]: [key: string, value: any]) => [string, any])
): any => {
  if (value == null) {
    return value
  }
  if (isArray(value)) {
    return value.map(val => processValue(val, customizer))
  } else if (isPlainObject(value)) {
    return processObject(value, customizer)
  } else if (typeof value === 'string') {
    if (isBoolean(value, { loose: true })) {
      return toBoolean(value, false)
    } else if (isoDateRegExp.test(value)) {
      return parseISO(value)
    }
    /**
     * @note This is removed... some DB numeric values are
     * intentionally strings. Converting from string to number
     * can lose precision.
     */
    // else if (isNumeric(value)) {
    //   return Number(value)
    // }
  }
  return value
}

const { isArray } = Array

export const IS_PROCESSED = Symbol('IS_PROCESSED')

export const processObject = (
  obj: any,
  customizer?: (([key, value]: [key: string, value: any]) => [string, any])
) => {
  customizer ??= entry => entry
  for (const entry of Object.entries(obj)) {
    const [key, value] = customizer(entry)
    obj[key] = processValue(value, customizer)
  }
  return obj
}

type JQueryXHR = ReturnType<typeof $.ajax>

/**
 * We attach a dataFilter so that any AJAX call not using the GeniusLinkServiceClient
 * will skip processing with the processObject function from jquery-proxy.ts.
 */
const beforeSend = (jqXHR: JQueryXHR) => {
  jqXHR.done((data: unknown) => {
    if (data && typeof data === 'object') {
      Object.defineProperty(data, IS_PROCESSED, {
        value: true
      })
    }
  })
}

const getAjaxSettings = (request: unknown): JQueryAjaxSettings => {
  const settings: JQueryAjaxSettings = {}

  if (isPlainObject(request)) {
    settings.data = JSON.stringify(request)
    settings.contentType = 'application/json; charset=utf-8'
    settings.processData = false
  } else {
    settings.data = request
  }
  return settings
}

/**
 * This is necessary for parsing JSV responses, so many
 * pages use this client, but some others get/post using jQuery's
 * ajax methods directly. I haven't found documentation on which
 * is why?
 *
 * @note - If we're ever to extract jQuery, we would need to
 * figure out how to extract jsv.js.
 */
export class GeniusLinkServiceClient extends JsvServiceClient {
  declare baseSyncReplyUri: string
  declare baseAsyncOneWayUri: string

  /**
   * @param {string} baseUrl
   */
  constructor(baseUrl?: string) {
    baseUrl ??= ''
    super(baseUrl)

    this.baseSyncReplyUri = baseUrl
    this.baseAsyncOneWayUri = baseUrl
  }

  /**
   * Promise-based method.
   *
   * @note - This doesn't call a Promise reject because
   * it's returning the flatry pattern, which returns
   * an array of [error, response]. This avoids needing
   * to wrap every call in a try/catch block.
   *
   * @see https://github.com/ymatuhin/flatry
   *
   * Flatry is used extensively in Booklinker.
   */
  request<Response extends Record<string, any> = Record<string, any>>(
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    url: string,
    request?: Record<string, any>,
    customizer?: (([key, value]: [key: string, value: any]) => [string, any])
  ): Promise<[Partial<Status>, undefined] | [null, Response]> {
    request ??= {}
    const { format = 'json', ...rest } = request
    request = {
      format,
      ...rest
    }

    return new Promise(resolve => {
      if (format === 'jsv') {
        super.send.call(
          this,
          url,
          request,
          (response: any) => {
            if (isPlainObject(response)) {
              resolve([null, processObject(response, customizer) as Response])
            } else {
              const error: Partial<Status> = new Error(UNKNOWN_ERROR)
              error.isSuccess = false
              resolve([error, undefined])
            }
          },
          (error: StatusError) => {
            resolve([error, undefined])
          },
          {
            beforeSend,
            type: method
          }
        )
      } else {
        void $.ajax({
          ...getAjaxSettings(rest),
          type: method,
          url,
          dataType: 'json',
          beforeSend,
          success: function(response) {
            resolve([null, processObject(response, customizer) as Response])
          },
          error: function(xhr) {
            resolve([{
              isSuccess: false,
              message: xhr.responseText
            }, undefined])
          }
        })
      }
    })
  }

  get<Response extends Record<string, any> = Record<string, any>>(
    url: string,
    request?: Record<string, any>,
    customizer?: (([key, value]: [key: string, value: any]) => [string, any])
  ) {
    return this.request<Response>('GET', url, request, customizer)
  }

  post<Response extends Record<string, any> = Record<string, any>>(
    url: string,
    request?: Record<string, any>,
    customizer?: (([key, value]: [key: string, value: any]) => [string, any])
  ) {
    return this.request<Response>('POST', url, request, customizer)
  }

  put<Response extends Record<string, any> = Record<string, any>>(
    url: string,
    request?: Record<string, any>,
    customizer?: (([key, value]: [key: string, value: any]) => [string, any])
  ) {
    return this.request<Response>('PUT', url, request, customizer)
  }

  delete<Response extends Record<string, any> = Record<string, any>>(
    url: string,
    request?: Record<string, any>,
    customizer?: (([key, value]: [key: string, value: any]) => [string, any])
  ) {
    return this.request<Response>('DELETE', url, request, customizer)
  }

  /** Request sent / processed as JSV by default */
  getFromService(
    url: string,
    request: Record<string, any>,
    successCallback?: (response: any) => void,
    errorCallback?: (error: Status) => void
  ) {
    return this.sendToService('GET', url, request, successCallback, errorCallback)
  }

  // Generic request without method defined (can pass in 'POST', 'PUT', 'DELETE') - request sent as JSV by default
  sendToService(
    httpMethod: 'GET' | 'POST' | 'DELETE' | 'PUT',
    url: string,
    request?: Record<string, any>,
    successCallback?: (response: any) => void,
    errorCallback?: (error: StatusError) => void
  ) {
    return new Promise((resolve, reject) => {
      request ??= {}
      const { format = 'json', ...rest } = request
      request = { format, ...rest }
      if (format === 'jsv') {
        super.send.call(
          this,
          url,
          request,
          (response: any) => {
            successCallback?.(response)
            resolve(response)
          },
          (error: StatusError) => {
            errorCallback?.(error)
            reject(error)
          },
          {
            beforeSend,
            type: httpMethod,
            processData: false
          }
        )
      } else {
        void $.ajax({
          ...getAjaxSettings(rest),
          type: httpMethod,
          url,
          beforeSend,
          success: function(response) {
            successCallback?.(response)
            resolve(response)
          },
          error: function(xhr) {
            errorCallback?.(xhr.response)
            reject(xhr.response)
          }
        })
      }
    })
  }

  // sendToService(
  //   httpMethod: 'GET' | 'POST' | 'DELETE' | 'PUT',
  //   url: string,
  //   request: Record<string, any>,
  //   onSuccess: (response: any) => void,
  //   onError: (error: StatusError) => void
  // ) {
  //   const parsedUrl = new URL(url, env.API_URL)
  //   /** Combine object with inline query params */
  //   for (const [key, value] of Object.entries(request)) {
  //     parsedUrl.searchParams.append(key, value)
  //   }
  //   this.send(
  //     parsedUrl.pathname,
  //     parsedUrl.search.slice(1),
  //     onSuccess,
  //     onError,
  //     {
  //       beforeSend,
  //       type: httpMethod,
  //       processData: false,
  //       contentType: 'application/json; charset=utf-8'
  //     }
  //   )
  // }
}

export const apiClient = new GeniusLinkServiceClient()
