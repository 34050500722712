/**
 * This file is used to proxy the jQuery library,
 * whenever we include `import $ from 'jquery'`
 */
import { processObject, IS_PROCESSED } from '~js/GeniusLinkServiceClient'
import isPlainObject from 'lodash/isPlainObject'
import { resolveClientUrl } from '~/utils/url'

const { $ } = window

/**
 * Make sure all API calls made by jQuery are
 * going through our API endpoint
 */
$.ajaxPrefilter((options, originalOptions, jqXHR) => {
  jqXHR.done(data => {
    if (isPlainObject(data) && !data[IS_PROCESSED]) {
      processObject(data)
    }
  })

  /** @todo add this to `useClient` */
  if (/^\/(api\/)?v[0-9]\//.test(options.url)) {
    /** For jQuery GET requests, data will be added as querystring vars to the URL */
    if (options.type.toUpperCase() === 'GET') {
      options.url = resolveClientUrl(options.url, options.data)
      options.data = undefined
    } else {
      if (typeof options.data === 'object' && options.data.format) {
        options.url = resolveClientUrl(options.url, { format: options.data.format })
        delete options.data.format
      } else {
        options.url = resolveClientUrl(options.url)
      }
    }
    /** JSV is sent as text */
    if (options.data?.type !== 'jsv') {
      options.dataType = 'json'
    }
    /** This is required to send / receive cookies cross-domain */
    options.xhrFields = {
      withCredentials: true
    }
  }
})

export default $
